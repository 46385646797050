<template>
    <WitModal
        v-model="showModal"
        title="Advanced Settings"
        variant="warning"
        disableDBlock
        size="lg"
        customClass="workflow-builder-map-settings__modal"
        @input="onModalChange"
    >
        <b-form-group label="Jobs at once" :label-cols="2" :horizontal="true">
            <b-row>
                <b-col cols="2">
                    <b-form-input
                        v-model="$v.maxConcurrency.$model"
                        type="number"
                        @input="
                            value => {
                                $v.maxConcurrency.$model = Number(value)
                                emitInput()
                            }
                        "
                        id="step-modal-map-concurrency"
                    ></b-form-input>
                </b-col>
                <b-col cols="8" class="vue-slider-col">
                    <vue-slider
                        v-model="$v.maxConcurrency.$model"
                        :min="1"
                        :max="30"
                        id="step-modal-map-slider"
                        @input="emitInput"
                    />
                    <Feedback :state="validateRef('maxConcurrency')" invalid="This field is required"></Feedback>
                </b-col>
            </b-row>
        </b-form-group>

        <b-row>
            <b-col>
                <b-button @click="finish" :disabled="isPageChecked && $v.$invalid" variant="primary">
                    <i class="icon-check"></i>
                    <span> Save</span>
                </b-button>
            </b-col>
        </b-row>
    </WitModal>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import WitModal from '@/components/Modals/WitModal.vue'
import Feedback from '@/components/Feedback.vue'

export default {
    components: {
        WitModal,
        Feedback,
        VueSlider,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            maxConcurrency: 0,
            showModal: false,
            isPageChecked: false,
        }
    },
    validations: {
        maxConcurrency: {required},
    },
    created() {
        this.init()
    },
    watch: {
        show() {
            this.init()
            this.isPageChecked = false
            this.$v.$reset()
        },
    },
    methods: {
        init() {
            this.maxConcurrency = this.value
            this.showModal = this.show
        },

        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty || this.isPageChecked ? !validateField.$invalid : null
        },

        emitInput() {
            this.$emit('input', this.maxConcurrency)
        },

        onModalChange() {
            this.$emit('update:show', this.showModal)
        },

        async finish() {
            this.isPageChecked = true
            await this.$nextTick()

            if (!this.$v.$invalid) {
                this.emitInput()

                this.showModal = false
                this.onModalChange()
            }
        },
    },
}
</script>

<style></style>
